import React, {useEffect, useState} from 'react';
import './Editor.scss';
import Button from 'react-bootstrap/Button';
import {useLocation} from 'react-router-dom';
import DatePickerModal from '../DatePickerModal/DatePickerModal';
import {BlockNoteEditor, BlockSchema, Block, PartialBlock} from '@blocknote/core';
import {BlockNoteView, useBlockNote} from '@blocknote/react';
import '@blocknote/core/style.css';
import initialMemoText from './initialMemo.json';

interface IProps {
    saveMemo: (isExisting: boolean, title: string, text: string, blocks: string, id?: string, sealedUntil?: string) => void;
    sealMemo: (id: string, sealedUntil: string) => void;
}

function Editor(props: IProps) {
    const {state} = useLocation();
    const [isSaveDisabled, setSaveDisabled] = React.useState<boolean>(true);
    const [isExisting, setIsExisting] = React.useState<boolean>(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [blocks, setBlocks] = useState<Block<BlockSchema>[] | PartialBlock[]>([{}]);

    const editor: BlockNoteEditor<BlockSchema> = useBlockNote({
        editable: !state?.viewOnly,
        onEditorContentChange: (editor) => {
            // Converts the editor's contents to an array of Block objects.
            setBlocks(editor.topLevelBlocks);
            isSaveDisabled && setSaveDisabled(false);
        },
        domAttributes: {
            editor: {
                class: 'editor'
            }
        }
    });

    const {saveMemo, sealMemo} = props;

    useEffect(() => {
        let blocks: PartialBlock[] = [{
            type: 'heading',
            props: {
                level: '2',
            },
            content: 'My awesome memo'
        }];

        if (state?.initialText) {
            blocks = initialMemoText as PartialBlock[];
        }

        if (state?.memo) {
            blocks = JSON.parse(state.memo.blocks);
            setIsExisting(true);
            setSaveDisabled(false);
        }
        const firstBlockId = editor.topLevelBlocks[0].id;
        editor.insertBlocks(blocks, firstBlockId);

        setBlocks(blocks);
    }, []);

    // eslint-disable-next-line
    function getText(inputBlocks: any, titleBlockId: string): string {
        const text = [];
        for (const block of inputBlocks) {
            if (block.content && block.content.length > 0 && block.id !== titleBlockId) {
                if (typeof block.content === 'string') {
                    text.push(block.content);
                } else {
                    text.push(block.content[0].text);
                }
            }
            if (block.children && block.children?.length > 0) {
                const subText = getText(block.children, titleBlockId);
                text.push(subText);
            }
        }
        return text.join(' ');
    }

    function getTitleAndText(blocks: (Block<BlockSchema> | PartialBlock)[]) {
        const titleBlock = blocks.find((block) => block.type = 'heading');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const title = titleBlock?.content?.[0].text || titleBlock?.content;
        const titleBlockId = titleBlock ? titleBlock.id : '';
        const text = getText(blocks, titleBlockId || '');

        return [title, text];
    }

    const handleSubmit = (sealDate: string) => {
        const [title, text] = getTitleAndText(blocks);
        saveMemo(isExisting, title, text, JSON.stringify(blocks), state?.memo?.id, sealDate);
        isExisting && sealMemo(state?.memo.id, sealDate);
    };

    return (
        <div className='editor-container'>
            <div className="mt-4">
                <BlockNoteView editor={editor} theme={'dark'}/>
            </div>
            {state?.viewOnly || <div className="action-buttons">
                <Button onClick={() => {
                    const blocksToDelete = editor.topLevelBlocks.map(block => block.id);
                    editor.removeBlocks(blocksToDelete);
                    setSaveDisabled(true);
                }}>Clear</Button>
                <Button disabled={isSaveDisabled} onClick={() => {
                    setModalShow(true);
                }}>Seal</Button>
                <Button disabled={isSaveDisabled} onClick={() => {
                    const [title, text] = getTitleAndText(blocks);
                    saveMemo(isExisting, title, text, JSON.stringify(blocks), state?.memo?.id);
                }}>Save</Button>
            </div>}
            <div>
                <DatePickerModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default Editor;