import gql from 'graphql-tag';

export const getMemoQuery = gql`
    query getMemo($id: ID!) {
        getMemo(id: $id) {
            id
            text
            blocks
        }
    }`;
