export function formatDate(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
}

export function formatInterval(dateToString: string, dateFromString: string | null = null) {
    const dateTo = new Date(dateToString);
    const dateFrom = dateFromString ? new Date(dateFromString) : new Date();

    // check for years
    const yearTo = dateTo.getFullYear();
    const yearFrom = dateFrom.getFullYear();
    if (yearTo !== yearFrom) {
        const diff = yearTo - yearFrom;
        return `${diff} year${diff !== 1 ? 's' : ''}`;
    }

    // check for months
    const monthTo = dateTo.getMonth();
    const monthFrom = dateFrom.getMonth();
    if (monthTo !== monthFrom) {
        const diff = monthTo - monthFrom;
        return `${diff} month${diff !== 1 ? 's' : ''}`;
    }

    // check for days
    const dayTo = dateTo.getDate();
    const dayFrom = dateFrom.getDate();
    if (dayTo !== dayFrom) {
        const diff = dayTo - dayFrom;
        return `${diff} day${diff !== 1 ? 's' : ''}`;
    }
    return 'today';
}

export function randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}