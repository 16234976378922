import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {NavbarBrand} from 'react-bootstrap';
import {IUserInfo} from '../../App';
import Button from 'react-bootstrap/Button';
import './Navigation.scss';

interface IProps {
	isSignedIn: boolean,
	user: IUserInfo | undefined,
	signOut: () => void,
}

function Navigation(props: IProps) {
    const {isSignedIn, user, signOut} = props;

    return (
        <Navbar className='navbar-container sticky-top' collapseOnSelect bg="primary" data-bs-theme="dark" expand="lg">
            <NavbarBrand className="ps-3" href="/">
                <img
                    src="/logo/logo-full-colour.png"
                    // width="30"
                    height="50"
                    className="d-inline-block align-top"
                    alt="Timemo logo"
                />
            </NavbarBrand>
            <Navbar.Toggle className="me-3" aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse>
                <Nav className="px-2 justify-content-end" style={{width: '100%'}}>
                    {isSignedIn && <><Navbar.Text className="me-3">{user?.attributes.name}</Navbar.Text> <Button
                        variant="orange"
                        onClick={signOut}>SignOut</Button></>}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
