import React from 'react';

export interface IDayPicker {
    onDayChange: (day: number) => void;
    year: number;
    month: number;
    day: number;
}

const DayPicker = (
    {
        onDayChange,
        year,
        month,
        day,
    }: IDayPicker) => {

    function getDaysInMonth(year: number, month: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    function renderDayOptions() {
        const days = getDaysInMonth(year, month);
        const dayOptions: JSX.Element[] = [];
        for (let i = 1; i <= days; ++i) {
            dayOptions.push(
                <option value={i} key={i} className="dropdown">
                    {i}
                </option>
            );
        }
        return dayOptions;
    }

    return (
        <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDayChange(+e.target.value)}
            value={day}
        >
            {renderDayOptions()}
        </select>
    );
};

export default DayPicker;