import gql from 'graphql-tag';

export const updateMemoMutation = gql`
    mutation updateMemo($memoId: ID!, $title: String!, $text: String!, $blocks: String!) {
        updateMemo(id: $memoId, title: $title, text: $text, blocks: $blocks) {
            id
            title
            text
            blocks
            updated
            created
        }
    }`;
