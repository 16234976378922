import gql from 'graphql-tag';

export const listMemoQuery = gql`
    query listMemo {
        listMemo {
            id
            title
            updated
            created
            status
            sealedUntil
			text
        }
    }`;
