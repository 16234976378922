import gql from 'graphql-tag';

export const createMemoMutation = gql`
    mutation createMemo($title: String!, $text: String!, $blocks: String!, $sealedUntil: String) {
        createMemo(title: $title, text: $text, blocks: $blocks, sealedUntil: $sealedUntil) {
            id
            title
            text
            blocks
            created
            updated
            sealedUntil
        }
    }`;
