import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import {Memo} from '../../types/Memo';
import {formatInterval} from '../../utils';
import {MemoModel} from '../../models/MemoModel';
import apiClientAppSync from '../../services/apiClientAppSync';
import {useNavigate} from 'react-router-dom';

interface IProps {
	setShowHero: (show: boolean) => void
	unreadMemo: Memo
}

function UnreadMemo(props: IProps) {
    const memoModel = new MemoModel(apiClientAppSync);
    const navigate = useNavigate();

    const {setShowHero, unreadMemo} = props;

    async function handleClick() {
        const memoData = await memoModel.getMemoById(unreadMemo.id);
        unreadMemo.blocks = memoData.blocks;
        navigate(`/editor/${unreadMemo.id}`, {
            state: {
                memo: unreadMemo,
                viewOnly: true,
            }
        });
        await memoModel.markAsRead(unreadMemo.id);
        setShowHero(false);
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
				New memo
                <CloseButton onClick={() => setShowHero(false)} />
            </Card.Header>
            <Card.Body className="text-center">
                <Card.Text>
					There is an unopened memo waiting to be read:
                </Card.Text>
                <Card.Title ><strong>{unreadMemo.title}</strong></Card.Title>
                <Card.Text className="pt-2">
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
					It has floated for {formatInterval(unreadMemo.sealedUntil!, unreadMemo.updated)}.
                </Card.Text>
                <Button variant="primary" onClick={async () => await handleClick()}>Read</Button>
            </Card.Body>
        </Card>
    );
}

export default UnreadMemo;
