import React from 'react';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import {Memo} from '../../../types/Memo';
import {formatDate} from '../../../utils';

interface IProps {
    newMemo: Memo,
    handleDelete: (event: React.MouseEvent<HTMLButtonElement>, memoId: string) => void
}

function NewMemo(props: IProps) {
    const navigate = useNavigate();

    const {newMemo, handleDelete} = props;

    function handleClick() {
        navigate(`/editor/${newMemo.id}`, {
            state: {
                memo: newMemo,
            }
        });
    }

    return (
        <div className="d-flex flex-column">
            <Button
                variant="primary-lighter"
                onClick={() => handleClick()}
            >
                <div className="d-flex justify-content-between">
                    <h4 className="mb-1">{newMemo.title}</h4>
                    <small>{formatDate(newMemo.updated)}</small>
                </div>
                <p className="mb-1 text-start">{newMemo.text}</p>
                <div className="d-flex justify-content-between">
                    <small>words: {newMemo.text.split(/\s+/).length}</small>
                    <small>from: {formatDate(newMemo.created)}</small>
                </div>
            </Button>
            <div className="d-flex justify-content-end">
                <Button
                    className="btn-sm btn-secondary mt-3"
                    onClick={(event) => handleDelete(event, newMemo.id)}
                >
                Delete
                </Button>
            </div>
        </div>
    );
}

export default NewMemo;
