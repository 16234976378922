import React from 'react';
import {Popover, PopoverBody, PopoverHeader} from 'react-bootstrap';

function Explanation() {
    return (
        <Popover id="popover-basic">
            <PopoverHeader as="h3">How <strong>seal</strong> works</PopoverHeader>
            <PopoverBody>
                <p>Just like with a time capsule, seal date is the date until when the capsule is sealed. During that time,
                    <strong>you cannot open, edit or delete</strong> your sealed memo.</p>
                <p>If you choose a <strong>specific date</strong>, be aware that the time zone is UTC and it is at least a year from now.
                    If you choose <strong>surprise me</strong>, the date will be sometime in the next 2 to 5 years.</p>
            </PopoverBody>
        </Popover>
    );
}

export default Explanation;