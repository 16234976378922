const rootElement = document.getElementById('root');

export const API_URL: string = rootElement?.getAttribute('data-api-url') || process.env.REACT_APP_API_URL || '';
export const AWS_REGION: string = rootElement?.getAttribute('data-aws-region') || process.env.REACT_APP_REGION || 'eu-west-1';
export const USER_POOL_ID: string = rootElement?.getAttribute('data-user-pool-id') || process.env.REACT_APP_USER_POOL_ID || '';
export const USER_POOL_WEB_CLIENT_ID: string = rootElement?.getAttribute('data-user-pool-web-client-id') || process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '';
export const HOSTED_UI_DOMAIN: string = rootElement?.getAttribute('data-hosted-ui-domain') || process.env.REACT_APP_HOSTED_UI_DOMAIN || '';
export const REDIRECT_SIGN_IN: string = rootElement?.getAttribute('data-redirect-sign-in') || process.env.REACT_APP_REDIRECT_SIGN_IN || 'http://localhost:3000';
export const REDIRECT_SIGN_OUT: string = rootElement?.getAttribute('data-redirect-sign-out') || process.env.REACT_APP_REDIRECT_SIGN_OUT || 'http://localhost:3000';

export const HEIGHT: number = window.innerHeight * 0.75;

export const RANDOMIZED_START_PERIOD = 2;
export const RANDOMIZED_END_PERIOD = 5;

export const TEXT_SHORT_WORD_COUNT = 25;

export const FOOTER_HEIGHT_PX = 180;
