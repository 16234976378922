import React from 'react';

export interface IMonthPicker {
    onMonthChange: (month: number) => void;
    selectedMonth: number;
}

const MonthPicker = (
    {
        onMonthChange,
        selectedMonth,
    }: IMonthPicker) => {

    const monthByNumber = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    function renderMonthOptions() {
        const monthOptions: JSX.Element[] = [];
        monthByNumber.forEach((month, index) => {
            monthOptions.push(
                <option value={index} key={index} className="dropdown">
                    {month}
                </option>
            );
        });
        return monthOptions;
    }

    return (
        <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onMonthChange(+e.target.value);
            }}
            value={selectedMonth}
        >
            {renderMonthOptions()}
        </select>
    );
};

export default MonthPicker;