import React from 'react';
import {Card} from 'react-bootstrap';
import {Memo} from '../../../types/Memo';
import {formatDate, formatInterval} from '../../../utils';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import {MemoModel} from '../../../models/MemoModel';
import apiClientAppSync from '../../../services/apiClientAppSync';
import {TEXT_SHORT_WORD_COUNT} from '../../../consts';

interface IProps {
	memo: Memo;
}

function OpenMemo(props: IProps) {
    const memoModel = new MemoModel(apiClientAppSync);
    const navigate = useNavigate();
    const {memo} = props;

    async function handleClick() {
        const memoData = await memoModel.getMemoById(memo.id);
        memo.blocks = memoData.blocks;
        navigate(`/editor/${memo.id}`, {
            state: {
                memo,
                viewOnly: true,
            }
        });
        await memoModel.markAsRead(memo.id);
    }

    return (
        <Card className='mt-4' bg='secondary' text='light'>
            <Card.Body>
                <Card.Title>{memo.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{formatDate(memo.updated)}</Card.Subtitle>
                <Card.Text>{memo.text.split(' ').slice(0, TEXT_SHORT_WORD_COUNT).join(' ')}...</Card.Text>
                <div className='d-flex align-items-center justify-content-between'>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    <Card.Subtitle className="mb-2 text-muted">sealed for {formatInterval(memo.sealedUntil!, memo.updated)}</Card.Subtitle>
                    <Button variant="primary" onClick={async () => await handleClick()}>Open</Button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default OpenMemo;
