import React from 'react';
import SealedMemo from '../Home/components/SealedMemo';
import {Memo} from '../../types/Memo';
import './Ocean.scss';

interface IProps {
	sealedMemos: Memo[];
}

function Ocean(props: IProps) {
    const {sealedMemos} = props;
    function renderSealedMemos() {
        return sealedMemos.map(memo => {
            return <SealedMemo key={memo.id} memo={memo}/>;
        });
    }

    return (
        <div className="ocean-container">
            <div className='d-flex' style={{height: 0}}>{sealedMemos.length > 0 && renderSealedMemos()}</div>
            <div className="ocean">
                <div className="wave-1"></div>
                <div className="wave-2"></div>
                <div className="wave-3"></div>
            </div>
        </div>
    );
}

export default Ocean;