import React from 'react';
import './EmptyState.scss';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';

interface IProps {
    sealedMemos: number;
}

function EmptyState(props: IProps) {
    const navigate = useNavigate();
    return (
        <div className='empty-state-container'>
            <img className='opened-bottle' src='/images/bottle/bottle-opened-transparent-nocork.png' alt='bottle-no-cork'/>
            <img className='cork' src='/images/bottle/cork-transparent.png' alt='cork'/>
            <h1 className='pt-2'>No readable memos</h1>
            <p>Write a memo or wait until the sealed ones are due!</p>
            <p>You have <strong>{props.sealedMemos || 0} sealed memo{props.sealedMemos !== 1 && 's'}</strong> floating in the sea.</p>
            <Button onClick={() => navigate('/editor')}>Start new memo</Button>
        </div>
    );
}

export default EmptyState;
