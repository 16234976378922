import {Memo, MemoStatus} from '../../types/Memo';
import './Home.scss';
import React, {useState} from 'react';
import NewMemo from './components/NewMemo';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OpenMemo from './components/OpenMemo';
import UnreadMemo from '../UnreadMemo/UnreadMemo';
import EmptyState from '../EmptyState/EmptyState';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import {FOOTER_HEIGHT_PX} from '../../consts';

interface IProps {
	newMemo: Memo | undefined,
	sealedMemos: Memo[],
    openMemos: Memo[],
	handleDelete: (event: React.MouseEvent<HTMLButtonElement>, memoId: string) => void,
    hasNewMemo: boolean,
}

function Home(props: IProps) {
    const {newMemo, sealedMemos, openMemos, handleDelete, hasNewMemo} = props;
    const unreadMemos = openMemos.filter(memo => memo.status === MemoStatus.SEALED);

    const [showHero, setShowHero] = useState(unreadMemos.length > 0);

    const navigate = useNavigate();

    function renderOpenMemos() {
        const memosElements = openMemos.map(memo => {
            return <OpenMemo key={memo.id} memo={memo}/>;
        });

        const half = Math.ceil(memosElements.length / 2);
        return <Row className="m-0">
            <Col sm={6} key={1}>
                {memosElements.slice(0, half)}
            </Col>
            <Col sm={6} key={2}>
                {memosElements.slice(half)}
            </Col>
        </Row>;
    }

    const emptyState = !newMemo && !openMemos.length;

    return (
        <div className="home-container">
            {emptyState ? <EmptyState sealedMemos={sealedMemos.length}/> : <>
                {showHero && <UnreadMemo setShowHero={setShowHero} unreadMemo={unreadMemos[0]}/>}
                <div className="mt-4" style={{
                    paddingBottom: `${FOOTER_HEIGHT_PX + 10}px`
                }}>
                    {newMemo && <NewMemo newMemo={newMemo} handleDelete={handleDelete}/>}
                    {openMemos.length !== 0 && <><h2>Opened memos</h2>{renderOpenMemos()}</>}
                </div>
            </>}

            {!hasNewMemo && <Button className="start-new-memo me-2" onClick={() => navigate('/editor')}>
                <i className="pen fa-solid fa-pen text-secondary"/>
            </Button>}
        </div>
    );
}

export default Home;
