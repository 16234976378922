import React from 'react';
import Image from 'react-bootstrap/Image';
import './Footer.scss';
import Ocean from '../Ocean/Ocean';
import {Memo} from '../../types/Memo';

interface IProps {
	sealedMemos: Memo[];
}

function Footer(props: IProps) {

    return (
        <div className="footer">
            <Ocean sealedMemos={props.sealedMemos}/>
            <footer className="footer-content d-flex flex-wrap justify-content-between align-items-center">
                <p className="col-md-4 mb-0 text-secondary">© 2024 Timemo</p>
                <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <Image src="logo/logo-round-colour.png" height="60"/>
                </a>
                <ul className="nav col-md-4 justify-content-end">
                    <a className="text-secondary" href="https://github.com/time-memo" target="_blank" rel="noreferrer noopener">
                        <li className="fab fa-github pe-4"/>
                    </a>
                    <a className="text-secondary" href="https://www.linkedin.com/in/tinazhouhui/" target="_blank" rel="noreferrer noopener">
                        <li className="fab fa-linkedin pe-4"/>
                    </a>
                    <a className="text-secondary" href="mailto:tinazhpublic@gmail.com">
                        <li className="fab fa-google"/>
                    </a>
                </ul>
            </footer>
        </div>
    );
}

export default Footer;