import React from 'react';

export interface IYearPicker {
    onYearChange: (year: number) => void;
    selectedYear: number;
}


const YearPicker = (
    {
        onYearChange,
        selectedYear,
    }: IYearPicker) => {

    const startYear = new Date().getFullYear() + 1;
    const endYear = startYear + 25;

    function renderYearOptions() {
        const years: number[] = [];

        for (let i = startYear; i <= endYear; i++) {
            years.push(i);
        }

        const yearOptions: JSX.Element[] = [];
        years.forEach((year, index) => {
            yearOptions.push(
                <option value={year} key={index} className="dropdown">
                    {year}
                </option>
            );
        });
        return yearOptions;
    }

    return (
        <select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onYearChange(+e.target.value);
            }}
            value={selectedYear}
        >
            {renderYearOptions()}
        </select>
    );
};

export default YearPicker;