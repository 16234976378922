import {createMemoMutation} from '../graphql/mutations/createMemo';
import {BaseModel} from './BaseModel';
import apiClientAppSync from '../services/apiClientAppSync';
import {listMemoQuery} from '../graphql/queries/listMemo';
import {Memo} from '../types/Memo';
import {ApolloQueryResult} from 'apollo-client';
import {deleteMemoMutation} from '../graphql/mutations/deleteMemo';
import {FetchResult} from 'apollo-link/src/types';
import {updateMemoMutation} from '../graphql/mutations/updateMemo';
import {sealMemoMutation} from '../graphql/mutations/sealMemo';
import {markAsReadMutation} from '../graphql/mutations/markAsRead';
import {getMemoQuery} from '../graphql/queries/getMemo';

export class MemoModel extends BaseModel {
    constructor(private readonly client: typeof apiClientAppSync) {
        super();
    }

    public static sortMemoFromLatestUpdated(memo: Memo[]): Memo[] {
        return memo.sort((a, b) => (a.updated > b.updated) ? -1 : ((a.updated < b.updated) ? 1 : 0));
    }

    public createMemo(userId: string, title: string, text: string, blocks: string, sealedUntil: string | null): Promise<Memo> {
        return (async () => {
            const memo: FetchResult<{ createMemo: Memo }> = await this.client.mutate({
                mutation: createMemoMutation,
                variables: {
                    title,
                    text,
                    blocks,
                    userId,
                    sealedUntil
                }
            });
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return memo.data!.createMemo;
        })();
    }

    public listMemoByUser(): Promise<Memo[]> {
        return (async () => {
            const memo: ApolloQueryResult<{ listMemo: Memo[] }> = await this.client.query({
                query: listMemoQuery,
                fetchPolicy: 'network-only',
            });

            return MemoModel.sortMemoFromLatestUpdated(memo.data.listMemo);
        })();
    }

    public getMemoById(id: string): Promise<Memo> {
        return (async () => {
            const memo: ApolloQueryResult<{ getMemo: Memo }> = await this.client.query({
                query: getMemoQuery,
                fetchPolicy: 'network-only',
                variables: {
                    id
                }
            });

            return memo.data.getMemo;
        })();
    }

    public updateMemo(memoId: string, title: string, text: string, blocks: string): Promise<Memo> {
        return (async () => {
            const memo: FetchResult<{ updateMemo: Memo }> = await this.client.mutate({
                mutation: updateMemoMutation,
                variables: {
                    memoId,
                    title,
                    text,
                    blocks
                }
            });
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return memo.data!.updateMemo;
        })();
    }

    public sealMemo(memoId: string, sealedUntil: string): Promise<Memo> {
        return (async () => {
            const memo: FetchResult<{ sealMemo: Memo }> = await this.client.mutate({
                mutation: sealMemoMutation,
                variables: {
                    memoId,
                    sealedUntil
                }
            });
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return memo.data!.sealMemo;
        })();
    }

    public deleteMemo(memoId: string): Promise<string> {
        return (async () => {
            const memo: FetchResult<{ deleteMemo: Memo }> = await this.client.mutate({
                mutation: deleteMemoMutation,
                variables: {
                    memoId
                }
            });
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return memo.data!.deleteMemo.id;
        })();
    }

    public markAsRead(memoId: string): Promise<Memo> {
        return (async () => {
            const memo: FetchResult<{ markAsRead: Memo }> = await this.client.mutate({
                mutation: markAsReadMutation,
                variables: {
                    memoId
                }
            });
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return memo.data!.markAsRead;
        })();
    }
}
