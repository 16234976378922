export enum MemoStatus {
    NEW = 'new',
    SEALED = 'sealed',
    DELIVERED = 'delivered',
    OPENED = 'opened'
}

export interface Memo {
    id: string,
    userId: string,
    text: string,
    blocks: string,
    title: string,
    created: string,
    updated: string,
    status: MemoStatus,
    sealedUntil: string | null,
}

export interface PickedDate {
    year: number,
    month: number,
    day: number
}