import React from 'react';
import Image from 'react-bootstrap/Image';
import {Memo} from '../../../types/Memo';
import {formatInterval, randomIntFromInterval} from '../../../utils';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './SealedMemo.scss';
import Popover from 'react-bootstrap/Popover';


interface IProps {
	memo: Memo;
}

function SealedMemo(props: IProps) {
    const {memo} = props;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const text = `still ${formatInterval(memo.sealedUntil!, memo.updated)}...`;

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>{text}</Popover.Body>
        </Popover>
    );

    const rotationDegree = randomIntFromInterval(-55, 55);
    const bottom = randomIntFromInterval(15, 25);
    const left = randomIntFromInterval(20, 80);

    return (
        <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={popover}>
            <a className='sealed-bottle-container' style={{
                bottom: `${bottom}px`,
                left: `${left}vw`,
            }}><Image className="sealed-bottle" style={{
                    rotate: `${rotationDegree}deg`
                }} src="images/bottle/bottle-closed-white.png" alt='sealed bottle gently rocking in the sea'/></a>
        </OverlayTrigger>
    );
}

export default SealedMemo;
