import React, {useEffect, useState} from 'react';
import './App.scss';
import './custom.scss';
import Editor from './components/Editor/Editor';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Home from './components/Home/Home';
import {Memo, MemoStatus} from './types/Memo';
import {MemoModel} from './models/MemoModel';
import apiClientAppSync from './services/apiClientAppSync';
import {Amplify, Auth} from 'aws-amplify';
import {
    AWS_REGION,
    HOSTED_UI_DOMAIN,
    REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT,
    USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID
} from './consts';
import Waves from './components/Waves/Waves';
import Navigation from './components/Navbar/Navigation';
import Footer from './components/Footer/Footer';

export interface IUserInfo {
    attributes: {
        sub: string,
        email: string,
        email_verified: boolean,
        name: string,
    },
    id: string | undefined,
    username: string
}

function App() {
    const navigate = useNavigate();
    const [newMemo, setNewMemo] = useState<Memo | undefined>();
    const [memos, setMemos] = useState<Memo[]>([]);
    const memoModel = new MemoModel(apiClientAppSync);
    const [loading, setLoading] = useState<boolean>(true);
    const [isSignedIn, setSignIn] = useState<boolean>(false);
    const [user, setUser] = useState<IUserInfo>();
    Amplify.configure({
        Auth: {
            region: AWS_REGION,
            userPoolId: USER_POOL_ID,
            userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
            mandatorySignIn: true,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {
                domain: HOSTED_UI_DOMAIN,
                scope: [
                    'openid',
                    'aws.cognito.signin.user.admin',
                    'email',
                ],
                redirectSignIn: REDIRECT_SIGN_IN,
                redirectSignOut: REDIRECT_SIGN_OUT,
                responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
            },
        },
    });

    useEffect(() => {
        (async () => {
            try {
                const user = await Auth.currentUserInfo() as IUserInfo;
                if (user) {
                    setSignIn(true);
                    setUser(user);
                    // get all memos by user
                    const memos = await memoModel.listMemoByUser();

                    // if no existing memos, redirect to editor
                    if (memos.length === 0) {
                        navigate('/editor', {
                            state: {
                                initialText: true,
                            }});
                    }

                    // check if working memo exists and adds text to it
                    const newMemo = memos.filter(memo => memo.status === MemoStatus.NEW)[0];
                    if (newMemo) {
                        const memoData = await memoModel.getMemoById(newMemo.id);
                        newMemo.blocks = memoData.blocks;
                        newMemo.text = memoData.text;
                    }
                    setNewMemo(newMemo); // todo: move into if?

                    // get sealed memos
                    const sealedMemos = memos.filter(memo => memo.status !== MemoStatus.NEW);
                    setMemos(sealedMemos);  // todo: also wrap in if?
                    setLoading(false);
                } else {
                    console.log('user not signed in');
                    try {
                        await Auth.federatedSignIn();
                    } catch (error: unknown) {
                        console.error(error);
                    }
                }
            } catch (error: unknown) {
                console.error(error);
                // todo redirect to error page
            }
        })();
    }, [isSignedIn]);

    function handleDelete(event: React.MouseEvent<HTMLButtonElement>, memoId: string) {
        (async () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const deletedMemoId = await memoModel.deleteMemo(memoId);
            setNewMemo(undefined);
        })();
    }

    /**
     * Function handles both save and seal if initiated from a new memo (no previous saves and therefore no record in database that
     * can be updated).
     */
    function handleSave(isExisting: boolean, title: string, text = '', blocks = '', id = '', sealedUntil: string | null = null) {
        (async () => {
            setLoading(true);
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            const memo = isExisting ? await memoModel.updateMemo(id, title, text, blocks) : await memoModel.createMemo(user!.username, title, text, blocks, sealedUntil);

            // sealing from new
            if (sealedUntil) {
                setMemos((memos) => {
                    return [...memos, memo];
                });
            } else {
                setNewMemo(memo);
            }
            setLoading(false);
        })();
        navigate('/');
    }


    function handleSeal(id: string, sealedUntil: string) {
        (async () => {
            setLoading(true);
            const sealedMemo = await memoModel.sealMemo(id, sealedUntil);
            setNewMemo(undefined);
            setMemos((memos) => {
                return [...memos, sealedMemo];
            });
            setLoading(false);
        })();
        navigate('/');
    }

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error: unknown) {
            console.error(error);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sealedMemos = memos.filter(memo => new Date(memo.sealedUntil!).getTime() > Date.now());
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const openMemos = memos.filter(memo => new Date(memo.sealedUntil!).getTime() <= Date.now());

    return (
        <div className="App">
            <Navigation isSignedIn={isSignedIn} user={user} signOut={signOut}/>
            {loading ? <Waves/> :
                <Routes>
                    <Route path="" element={<Home newMemo={newMemo} sealedMemos={sealedMemos} openMemos={openMemos} handleDelete={handleDelete} hasNewMemo={!!newMemo}/>}/>
                    <Route path="editor"
                        element={newMemo ? <Home newMemo={newMemo} sealedMemos={sealedMemos} openMemos={openMemos} handleDelete={handleDelete} hasNewMemo={!!newMemo}/> :
                            <Editor saveMemo={handleSave} sealMemo={handleSeal}/>}/>
                    <Route path="editor/:memoId" element={<Editor saveMemo={handleSave} sealMemo={handleSeal}/>}/>
                </Routes>}
            <Footer sealedMemos={sealedMemos}/>
        </div>
    );
}

export default App;
