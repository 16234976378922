import React from 'react';
import {Button, Modal, OverlayTrigger} from 'react-bootstrap';
import DatePicker from '../DatePicker/DatePicker';
import {RANDOMIZED_END_PERIOD, RANDOMIZED_START_PERIOD} from '../../consts';
import Explanation from './components/Explanation';

interface IProps {
    show: boolean,
    onHide: () => void,
    handleSubmit: (sealDate: string) => void
}

function getRandomDate() {
    const today = new Date();
    const start = new Date().setFullYear(today.getFullYear() + RANDOMIZED_START_PERIOD);
    const end = new Date().setFullYear(today.getFullYear() + RANDOMIZED_END_PERIOD);
    return new Date(+start + Math.random() * (+end - +start));
}

const DatePickerModal = (props: IProps) => {
    const today = new Date();
    const initialDateState = {year: today.getFullYear() + 1, month: today.getMonth(), day: today.getDate()};
    const [date, setDate] = React.useState(initialDateState);
    const [showError, setError] = React.useState(false);
    const submit = (randomize = false) => {
        let finalDate;
        if (randomize) {
            finalDate = getRandomDate();
        } else {
            finalDate = new Date(date.year, date.month, date.day + 1);
        }

        if (finalDate < today) {
            setError(true);
            return;
        }

        const formattedDate = finalDate.toISOString().split('T')[0];
        props.handleSubmit(formattedDate);
        setDate(initialDateState);
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="date-picker-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="date-picker-modal">
                    Seal memo until:
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You will not be able to see or edit the content of the memo until this day.</p>
                <div className="d-flex justify-content-around">
                    <Button className="me-2" onClick={() => submit(true)}>Surprise me</Button>
                    <div className="d-flex flex-column">
                        <DatePicker date={date} setDate={setDate}/>
                        <Button onClick={() => submit()}>
                            Seal
                        </Button>
                    </div>
                </div>
                {showError &&  <p className='d-flex justify-content-end text-primary my-1'>Cannot seal a memo to a date from the past.</p>}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <OverlayTrigger trigger="click" placement="right" overlay={Explanation()}>
                    <Button className="btn-secondary">How does it work</Button>
                </OverlayTrigger>
                <Button className="btn-secondary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DatePickerModal;