import gql from 'graphql-tag';

export const sealMemoMutation = gql`
    mutation sealMemo($memoId: ID!, $sealedUntil: String!) {
        sealMemo(id: $memoId, sealedUntil: $sealedUntil) {
            id
            title
            updated
            sealedUntil
        }
    }`;
