import React from 'react';
import './DatePicker.scss';
import {PickedDate} from '../../types/Memo';
import YearPicker from './components/YearPicker';
import MonthPicker from './components/MonthPicker';
import DayPicker from './components/DayPicker';

interface IProps {
    date: PickedDate,
    setDate: React.Dispatch<React.SetStateAction<{ year: number, month: number, day: number }>>
}

const DatePicker = (props: IProps) => {
    const {date, setDate} = props;
    return (
        <div className="d-flex flex-row justify-content-around">
            <YearPicker
                selectedYear={date.year}
                onYearChange={(year: number) => {
                    setDate((prev) => ({...prev, year}));
                }}
            />

            <MonthPicker
                selectedMonth={date.month}
                onMonthChange={(month: number) => {
                    setDate((prev) => ({...prev, month}));
                }}
            />

            <DayPicker
                year={date.year}
                month={date.month}
                day={date.day}
                onDayChange={(day: number) => {
                    setDate((prev) => ({...prev, day}));
                }}
            />
        </div>
    );
};

export default DatePicker;